import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StaticPage } from '../../../containers';
import Header from '../../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../../newPages/NewLandingPage/Sections/Footer/Footer';

import { Link } from 'react-router-dom';
import { fetchLandscapes } from '../../../components/ChooseLandscape/fetchLandscapes';

import { LightGreenBtn, OrangeBtn } from '../../../newComponents/Button/ButtonSkin';

import blogTitleImage from '../../../assets/newAssets/icons/blog-title-line-d.svg';

import locationPinHover from '../../../assets/newAssets/icons/orange-location-pin.svg';

import mapIcon from '../../../assets/newAssets/icons/map-icon.svg';
import cottonIcon from '../../../assets/newAssets/icons/cotton-icon.svg';
import leafIcon from '../../../assets/newAssets/icons/leaf-icon.svg';
import guardianTeamBottom from '../../../assets/newAssets/guardians/guardian-team-bottom.webp';
import guardianTeamTop from '../../../assets/newAssets/guardians/guardian-team-top.webp';
import guardianTeamRight from '../../../assets/newAssets/guardians/guardian-team-right.webp';
import guardianTeamLeft from '../../../assets/newAssets/guardians/guardian-team-left.webp';
import bgGreenTexture from '../../../assets/newAssets/bg-texture-green.jpg';
import CustomRadioSelect from '../../../newComponents/SelectRadioDropdown';
import BlogSubscribe from '../../Blogs/BlogListPage/BlogSubscribe/index';
import { FaMap } from 'react-icons/fa';
import { FaListUl } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

import { LandscapeListSection, LandscapeGuardians } from './Styles';
import MapLinks from './MapLinks';

const data = [
    {
        id: 1,
        img: `${mapIcon}`,
    },
    {
        id: 2,
        img: `${cottonIcon}`,
    },
    {
        id: 3,
        img: `${leafIcon}`,
    },
];

const LandscapeList = ({ location, history, user }) => {
    const [landscapes, setLandscapes] = useState(null);
    const [selectedLandscape, setSelectedLandscape] = useState('');

    // toggle map
    const [isToggled, setIsToggled] = useState(false);
    const toggleClick = () => {
        setIsToggled(!isToggled);
    };

    useEffect(() => {
        fetchLandscapes()
            .then(resp => {
                if (resp) {
                    setLandscapes(resp);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const getHighlightedText = data => {
        let temp = data.slice(4);
        const index = temp.indexOf('**');
        const highlighted = temp.slice(0, index);
        const normal = temp.slice(index + 2);
        return { text: normal, highlightedPart: highlighted };
    };

    const transformedLandscapeOptions = [
        { name: 'countryTarget', value: <FormattedMessage id="LandscapeListing.SelectPrompt" /> }, // Default object
        ...(landscapes
            ? landscapes.map(({ name, titleSlug }) => ({
                  name: titleSlug,
                  value: name,
              }))
            : []),
    ];

    return (
        <StaticPage
            title="Make Nature your business - Regenera | Carbon Offsetting Company Peru | Reduce Ecological & Carbon Footprints in Peru"
            description="Regenera: Best environmental consulting company in Peru, our team helps your organization make a positive impact on nature, climate, and communities. Beyond carbon offsets and tree planting, Regenera supports the management of living landscapes, led by those who know their lands best."
            schema={{
                '@context': 'http://schema.org',
                '@type': 'HomePage',
                description:
                    'Empresas e instituciones de todo tamaño que velan por la naturaleza transformando sus impactos ambientales en acciones positivas.',
                name: 'Home',
            }}
        >
            <Header currentPath={location.pathname} user={user} history={history} />
            <LandscapeListSection pinHover={locationPinHover} bgGreenTexture={bgGreenTexture}>
                <div className="listing-first-section">
                    <div className="landscape-herobox relative">
                        {/* peru map */}
                        <div className={`peru-map hide-tab`}>
                            <MapLinks />
                        </div>
                        {/* peru map ends */}
                        <div className="left-side-content flex flex-col">
                            {/* title section */}
                            <div className="title-box relative">
                                <h1 className="text-white">
                                    <FormattedMessage id="LandscapeListing.Title" />
                                </h1>
                                <img
                                    src={blogTitleImage}
                                    class="w-auto absolute border-img"
                                    alt=""
                                />
                            </div>
                            <p className="text-white">
                                <FormattedMessage id="LandscapeListing.Desc" />
                            </p>
                            <div className={isToggled ? 'select-lists bg-green' : 'select-lists'}>
                                {/* select box section */}
                                <div className="input-box-section">
                                    <label className="text-white hide-tab">
                                        <FormattedMessage id="LandscapeListing.SelectTitle" />
                                    </label>

                                    {landscapes && (
                                        <CustomRadioSelect
                                            name="targetLandscape"
                                            classname="form-control"
                                            optionsList={transformedLandscapeOptions}
                                            value={selectedLandscape}
                                            onChange={value => {
                                                history.push(`/landscapes/${value}`);
                                            }}
                                            disabled={false}
                                        />
                                    )}

                                    <LightGreenBtn
                                        onClick={toggleClick}
                                        className="map-list-btn flex"
                                    >
                                        {isToggled ? (
                                            <div className="flex items-center map-text">
                                                <FormattedMessage id="LandscapeListing.ListLabel" />
                                                <FaListUl />
                                            </div>
                                        ) : (
                                            <div className="flex items-center map-text">
                                                <FormattedMessage id="LandscapeListing.MapLabel" />{' '}
                                                <FaMap />
                                            </div>
                                        )}
                                    </LightGreenBtn>
                                </div>
                                {/* card listing */}
                                <div className="article-section flex flex-wrap items-start justify-space-between">
                                    {/* peru map */}
                                    <div
                                        className={
                                            isToggled
                                                ? 'peru-map show-tab'
                                                : 'peru-map show-tab hidden'
                                        }
                                    >
                                        <MapLinks />
                                    </div>
                                    {/* peru map ends */}
                                    {landscapes &&
                                        landscapes.map(landscape => {
                                            return (
                                                <div
                                                    className={
                                                        isToggled
                                                            ? 'card-slider hidden'
                                                            : 'card-slider'
                                                    }
                                                >
                                                    <div className="discover-card w-full">
                                                        {landscape.id === 6 && (
                                                            <div className="tag absolute">
                                                                <FormattedMessage id="LandscapeListing.NewLabel" />
                                                            </div>
                                                        )}
                                                        <div className="card-img-btn">
                                                            <div className="card-img">
                                                                <picture>
                                                                    <source
                                                                        media="(max-width:700px)"
                                                                        srcset={
                                                                            landscape &&
                                                                            landscape.sliderImage
                                                                        }
                                                                    />
                                                                    <img
                                                                        src={
                                                                            landscape &&
                                                                            landscape.sliderImage
                                                                        }
                                                                        className="w-full"
                                                                        alt="landscape"
                                                                    />
                                                                </picture>
                                                            </div>
                                                            <LightGreenBtn className="card-button hide-tab">
                                                                <Link
                                                                    to={`/landscapes/${landscape.titleSlug}`}
                                                                >
                                                                    <FormattedMessage id="LandscapeListing.DiscoverLabel" />
                                                                </Link>
                                                            </LightGreenBtn>
                                                        </div>
                                                        <div className="discover-card-info">
                                                            <div className="card-title">
                                                                <FormattedMessage
                                                                    id="NewLandingPage.ourLandscapes.landscapeTitle"
                                                                    values={{
                                                                        landscape: landscape.name,
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="card-info">
                                                                <FormattedMessage
                                                                    id="NewLandingPage.ourLandscapes.landscapeDesc"
                                                                    values={{
                                                                        description:
                                                                            landscape.metadata.description.indexOf(
                                                                                '.'
                                                                            ) === -1
                                                                                ? landscape.metadata
                                                                                      .description
                                                                                : landscape.metadata.description
                                                                                      .split('.')[0]
                                                                                      .trim() + '.',
                                                                    }}
                                                                />
                                                            </p>
                                                            <hr />
                                                            <div className="icon-with-numbers flex flex-col">
                                                                <ul>
                                                                    {landscape.metadata.impact
                                                                        .split('\n')
                                                                        .map(itemSet =>
                                                                            getHighlightedText(
                                                                                itemSet
                                                                            )
                                                                        )
                                                                        .map((item, index) => (
                                                                            <li className="flex items-center">
                                                                                <div className="icon-box flex items-center justify-center">
                                                                                    <img
                                                                                        src={
                                                                                            data[
                                                                                                index
                                                                                            ].img
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="number-info">
                                                                                    <strong>
                                                                                        {
                                                                                            item.highlightedPart
                                                                                        }
                                                                                    </strong>{' '}
                                                                                    {item.text}
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                                <LightGreenBtn className="card-button">
                                                                    <Link
                                                                        to={`/landscapes/${landscape.titleSlug}`}
                                                                    >
                                                                        {' '}
                                                                        <FormattedMessage id="NewLandingPage.partnersImpact.discoverMore" />
                                                                    </Link>
                                                                </LightGreenBtn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    <div className="card-slider">
                                        <div className="propose-box">
                                            <h4 className="small-title">
                                                <FormattedMessage id="LandscapeListing.ProposeLandscape" />
                                            </h4>
                                            <Link to="#" className="green-link flex items-center">
                                                <MdEmail className="link-icon" />
                                                <FormattedMessage id="LandscapeListing.SendMessage" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LandscapeGuardians>
                    <div className="team-section flex justify-center flex-col">
                        <div className="team-bg"></div>
                        <div className="title-box mx-auto">
                            <div className="small-title">
                                <FormattedMessage id="LandscapeListing.LandscapeGuardianTitle" />
                            </div>
                            <h3>
                                <FormattedMessage id="LandscapeListing.LandscapeGuardianTitleMessage" />
                            </h3>
                        </div>
                        <div className="content mx-auto">
                            <FormattedHTMLMessage
                                id="LandscapeListing.LandscapeGuardianDesc"
                                values={{
                                    p: chunks => <p>{chunks}</p>,
                                }}
                            />
                            <div className="group-btns justify-center flex-wrap flex">
                                <OrangeBtn>
                                    <Link to="/organizations">
                                        <FormattedMessage id="LandscapeListing.SupportGuardian" />
                                    </Link>
                                </OrangeBtn>
                                <LightGreenBtn>
                                    <Link to="/land-managers">
                                        <FormattedMessage id="LandscapeListing.BecomeGuardian" />
                                    </Link>
                                </LightGreenBtn>
                            </div>
                        </div>
                    </div>
                    <div className="left-team">
                        <picture>
                            <source media="(max-width:1023px)" srcset={guardianTeamTop} />
                            <img src={guardianTeamLeft} alt="team-img" />
                        </picture>
                    </div>
                    <div className="right-team">
                        <picture>
                            <source media="(max-width:1023px)" srcset={guardianTeamBottom} />
                            <img src={guardianTeamRight} alt="team-img" />
                        </picture>
                    </div>
                </LandscapeGuardians>

                <BlogSubscribe />
            </LandscapeListSection>
            <Footer history={history} />
        </StaticPage>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(LandscapeList));
