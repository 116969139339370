// const CreatingDocument = ({ onDocumentReady }) => {
//     const [progress, setProgress] = useState(0);

//     useEffect(() => {
//         const generateDocument = async () => {
//             const response = await fetch('/api/generate-document', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//             });

//             const reader = response.body.getReader();
//             const decoder = new TextDecoder('utf-8');

//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) break;

//                 const chunk = decoder.decode(value);
//                 const parsedChunk = JSON.parse(chunk);
//                 setProgress(parsedChunk.progress);

//                 if (parsedChunk.status === 'completed') {
//                     onDocumentReady(parsedChunk.documentUrl);
//                     break;
//                 }
//             }
//         };

//         generateDocument();
//     }, [onDocumentReady]);

//     return (
//         <div className="loading-container">
//             <h2>Creating document</h2>
//             <p>We’re creating the Letter of Intent. This will only take a few moments.</p>
//             <div className="progress-bar">
//                 <div className="progress" style={{ width: `${progress}%` }}></div>
//             </div>
//         </div>
//     );
// };

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { generateInterestDocument } from '../../utils';

const CreatingDocSection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .title-box {
        img {
            max-width: 67px;
            margin-bottom: 32px;
        }
    }
    .creation-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 50px;
            .progress-bar {
                height: 15px;
                max-width: 285px;
                border-radius: 25px;
                --bulma-progress-border-radius: 25px;
                --bulma-progress-bar-background-color: #ffffff;
                --bulma-progress-value-background-color: #6ea44c;
                --bulma-progress-indeterminate-duration: 1.5s;
                border: 1px solid #ccc;
                appearance: none;
                border: none;
                overflow: hidden;
                padding: 0;
            }
        }
    }
    @media (max-width: 500px) {
        .creation-box {
            padding: 100px 20px 120px;
        }
    }
`;
const CreatingDocument = ({ name, email, region, onDocumentReady }) => {
    const [progress, setProgress] = useState(0);

    const [showError, setShowError] = useState(null);
    useEffect(() => {
        const generateDocument = async () => {
            try {
                const response = await generateInterestDocument({ name, email, region });

                if (response.status === 'success') {
                    const data = await response.data;

                    setProgress(data.progress);
                    onDocumentReady(data.documentUrl);
                } else {
                    setShowError(response.message);
                }
            } catch (err) {
                setShowError(err.message);
            }
        };

        generateDocument();
    }, [name, email, onDocumentReady]);

    return (
        <CreatingDocSection className="mx-auto flex justify-center items-center">
            <div className="creation-box mx-auto text-center relative">
                {showError ? <p>Error: {showError}</p> : null}
                <div className="title-box text-center">
                    <img src="../images/doc-create-ic.webp" alt="creating doc process" />
                    <h2>Creating document</h2>
                </div>
                <p>We're creating the Letter of Interest. This will only take a few moments.</p>
                <div className="input-box-section">
                    <progress className="progress-bar w-full mx-auto" value={progress} max={100} />
                </div>
            </div>
        </CreatingDocSection>
    );
};

export default CreatingDocument;
