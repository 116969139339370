import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import acuicultura from '../../assets/actividades/acuicultura.jpg';
import agriculturaManu from '../../assets/actividades/agriculturaManu.jpg';
import agriculturaOrganica from '../../assets/actividades/agriculturaOrganica.jpg';
import artesaniaManu from '../../assets/actividades/artesaniaManu.jpg';
import artesanias from '../../assets/actividades/artesanias.jpg';
import ganaderiaAlpaquera from '../../assets/actividades/ganaderiaAlpaquera.jpg';
import manejoForestal from '../../assets/actividades/manejoForestal.jpg';
import manejoPesquero from '../../assets/actividades/manejoPesquero.jpg';
import turismo from '../../assets/actividades/turismo.jpg';
import turismoComunitario from '../../assets/actividades/turismoComunitario.jpg';
import turismoManu from '../../assets/actividades/turismoManu.jpg';

import reforestacion from '../../assets/actividades/reforestacion.png'; // Reforestation
import ecoturismo from '../../assets/actividades/ecoturismo.png'; // Ecotourism
import cocaLegal from '../../assets/actividades/cocaLegal.png'; // Legal coca leaf

import aguilaArpia from '../../assets/especies/aguilaArpia.jpg';
import arahuana from '../../assets/especies/arahuana.jpg';
import camaronDeRio from '../../assets/especies/camaronDeRio.jpg';
import colibri from '../../assets/especies/colibri.jpg';
import colibriDeRaquetas from '../../assets/especies/colibriDeRaquetas.jpg';
import colibriDeVientreBlanco from '../../assets/especies/colibriDeVientreBlanco.jpg';
import condorAndino from '../../assets/especies/condorAndino.jpg';
import guacamayoEscarlata from '../../assets/especies/guacamayoEscarlata.jpg';
import jaguar from '../../assets/especies/jaguar.jpg';
import loboDeRio from '../../assets/especies/loboDeRio.jpg';
import mirloAcuático from '../../assets/especies/mirloAcuático.jpg';
import nutriaDeRio from '../../assets/especies/nutriaDeRio.jpg';
import osoDeAnteojos from '../../assets/especies/osoDeAnteojos.jpg';
import primate from '../../assets/especies/primate.jpg';
import tapir from '../../assets/especies/tapir.jpg';
import venadoDeColaBlanca from '../../assets/especies/venadoDeColaBlanca.jpg';
import vicunas from '../../assets/especies/vicunas.jpg';

import osoAndino from '../../assets/especies/osoAndino.png'; // Andean Bear
import gallitoDeLasRocas from '../../assets/especies/gallitoDeLasRocas.png'; // Andean Cock-of-the-Rock
import colibriPicoDeHoz from '../../assets/especies/colibriPicoDeHoz.png'; // Buff-tailed Sicklebill

import development from '../../assets/icons/development.svg';
import enterprise from '../../assets/icons/enterprise.svg';
import mountainsfull from '../../assets/icons/mountainsfull.svg';
import afpPrima from '../../assets/logo-aliados/afpPrima.jpg';
import AmazonasExplorer from '../../assets/logo-aliados/AmazonasExplorer.png';
import AndeanLodges from '../../assets/logo-aliados/AndeanLodges.png';
import auqui from '../../assets/logo-aliados/auqui.jpg';
import cedes from '../../assets/logo-aliados/cedes.jpg';
import cedia from '../../assets/logo-aliados/cedia.jpg';
import cientifica from '../../assets/logo-aliados/cientifica.png';
import explorandes from '../../assets/logo-aliados/explorandes.png';
import FloraYFauna from '../../assets/logo-aliados/FloraYFauna.jpg';
import gerpal from '../../assets/logo-aliados/gerpal.png';
import helvetasPeru from '../../assets/logo-aliados/helvetasPeru.png';
import hoseg from '../../assets/logo-aliados/hoseg.png';
import patronatoNYC from '../../assets/logo-aliados/patronatoNYC.jpg';
import peru2021 from '../../assets/logo-aliados/peru2021.jpg';
import pukanina from '../../assets/logo-aliados/pukanina.jpg';
import Rainforest from '../../assets/logo-aliados/Rainforest.jpg';
import SanDiegoZoo from '../../assets/logo-aliados/SanDiegoZoo.jpg';
import SePeru from '../../assets/logo-aliados/SePeru.jpg';
import strategik from '../../assets/logo-aliados/strategik.png';
import ccArin from '../../assets/logos-guardianes/ccArin.jpg';
import ccKiunalla from '../../assets/logos-guardianes/ccKiunalla.png';
import ccLaraos from '../../assets/logos-guardianes/ccLaraos.jpg';
import ccOllantaytambo from '../../assets/logos-guardianes/ccOllantaytambo.jpg';
import ccPiscacucho from '../../assets/logos-guardianes/ccPiscacucho.jpg';
import ccTanta from '../../assets/logos-guardianes/ccTanta.jpg';
import cnMatoriato from '../../assets/logos-guardianes/cnMatoriato.jpg';
import cnNuevaEsperanza from '../../assets/logos-guardianes/cnNuevaEsperanza.png';
import cnWicungo from '../../assets/logos-guardianes/cnWicungo.png';
import comunidadNativaShipetari from '../../assets/logos-guardianes/comunidadNativaShipetari.jpg';
import CREES from '../../assets/logos-guardianes/CREES.jpg';
import FamiliaChallco from '../../assets/logos-guardianes/FamiliaChallco.jpg';
import FamiliaIsidro from '../../assets/logos-guardianes/FamiliaIsidro.jpg';

import fecoritayb from '../../assets/logos-guardianes/FECORITAYB.png';
import IslaDeLosValles from '../../assets/logos-guardianes/IslaDeLosValles.jpg';
import PalotoaTeparo from '../../assets/logos-guardianes/PalotoaTeparo.jpg';

import predioAquize from '../../assets/logos-guardianes/predioAquize.png';
import predioHuaman from '../../assets/logos-guardianes/predioHuaman.png';

import abancay from '../../assets/maps/abancay.webp';
import canete from '../../assets/maps/canete.webp';
import manu from '../../assets/maps/manu.webp';
import tapiche from '../../assets/maps/requena.webp';
import machupicchu from '../../assets/maps/machupicchu.webp';
import paucartambo from '../../assets/maps/paucartambo.webp';
import {
    LayoutSingleColumn,
    LayoutWrapperFooter,
    LayoutWrapperMain,
    LayoutWrapperTopbar,
    NamedLink,
    Page,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import * as coreAPI from '../../coreApi';
import { richText } from '../../util/richText';
import css from './ListingPage.css';
import ListingProductContent from './ListingProductContent';
import SectionHeading from './SectionHeading';
import SectionHostMaybe from './SectionHostMaybe';
import Header from '../../newPages/NewLandingPage/Sections/Header/Header';
import Footer from '../../newPages/NewLandingPage/Sections/Footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const logoAliados = {
    afpPrima: { image: afpPrima, id: '5da7b5cc-d0b6-4113-9eac-a1cb9e3ead38' },
    AmazonasExplorer: { image: AmazonasExplorer, id: '5da7b6cf-b5bb-4d42-b1ab-736c3f74e287' },
    AndeanLodges: { image: AndeanLodges, id: '5da7b454-5edc-4c2d-9922-a8455051a93f' },
    auqui: { image: auqui, id: '5da7b485-ba34-455d-b58a-33c46e616f64' },
    cientifica: { image: cientifica, id: '5da7b603-8664-4164-baf0-b2693347b655' },
    explorandes: { image: explorandes, id: '5da7b296-901f-4c85-ae01-834edb9477a2' },
    gerpal: { image: gerpal, id: '5da7b6f6-1d41-4f30-97dd-74ad33066d55' },
    hoseg: { image: hoseg, id: '5da7b6a8-3ae4-4ef5-9313-5d6ec787414c' },
    patronatoNYC: { image: patronatoNYC, id: '5da3fdd5-ce09-4a44-81bd-562f2e602d53' },
    pukanina: { image: pukanina, id: '5da7b325-8a45-4867-83d4-33ca35e76a18' },
    strategik: { image: strategik, id: '5da7b56a-ccd5-433f-b5f6-bcfdd0c08214' },
    FloraYFauna: { image: FloraYFauna, id: '5da7b4a7-832f-4c37-b576-ef3f55edbc87' },
    peru2021: { image: peru2021, id: '5da7b3b0-a8cc-4361-a3b9-bd08c29eafb9' },
    Rainforest: { image: Rainforest, id: '5da7b59d-94d0-4adb-9ebe-8221c2343adf' },
};

const species = {
    colibri: colibri,
    primate: primate,
    aguilaArpia: aguilaArpia,
    arahuana: arahuana,
    camaronDeRio: camaronDeRio,
    colibriDeRaquetas: colibriDeRaquetas,
    colibriDeVientreBlanco: colibriDeVientreBlanco,
    condorAndino: condorAndino,
    mirloAcuático: mirloAcuático,
    nutriaDeRio: nutriaDeRio,
    osoDeAnteojos: osoDeAnteojos,
    tapir: tapir,
    venadoDeColaBlanca: venadoDeColaBlanca,
    vicunas: vicunas,
    guacamayoEscarlata: guacamayoEscarlata,
    jaguar: jaguar,
    loboDeRio: loboDeRio,
    osoAndino: osoAndino,
    gallitoDeLasRocas: gallitoDeLasRocas,
    colibriPicoDeHoz: colibriPicoDeHoz,
};

const activities = {
    acuicultura: acuicultura,
    agriculturaOrganica: agriculturaOrganica,
    artesanias: artesanias,
    ganaderiaAlpaquera: ganaderiaAlpaquera,
    manejoForestal: manejoForestal,
    manejoPesquero: manejoPesquero,
    turismo: turismo,
    turismoComunitario: turismoComunitario,
    artesaniaManu: artesaniaManu,
    turismoManu: turismoManu,
    agriculturaManu: agriculturaManu,
    reforestacion: reforestacion,
    ecoturismo: ecoturismo,
    cocaLegal: cocaLegal,
};

const logoGuardians = {
    ccArin: ccArin,
    cedes: cedes,
    helvetasPeru: helvetasPeru,
    ccKiunalla: ccKiunalla,
    ccLaraos: ccLaraos,
    ccOllantaytambo: ccOllantaytambo,
    ccPiscacucho: ccPiscacucho,
    ccTanta: ccTanta,
    cnNuevaEsperanza: cnNuevaEsperanza,
    cnWicungo: cnWicungo,
    comunidadNativaShipetari: comunidadNativaShipetari,
    PalotoaTeparo: PalotoaTeparo,
    FamiliaChallco: FamiliaChallco,
    FamiliaIsidro: FamiliaIsidro,
    IslaDeLosValles: IslaDeLosValles,
    CREES: CREES,
    cedia: cedia,
    fecoritayb: fecoritayb,
    cnMatoriato: cnMatoriato,
    SanDiegoZoo: SanDiegoZoo,
    predioAquize: predioAquize,
    predioHuaman: predioHuaman,
    SePeru: SePeru,
};

const maps = {
    abancay: abancay,
    canete: canete,
    manu: manu,
    tapiche: tapiche,
    'machu-picchu': machupicchu,
    paucartambo: paucartambo,
};

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

function LandscapePage(props) {
    const landscapeSlug = props.params.slug;

    const [loadProgress, setLoadProgress] = useState(true);
    const [landscapeData, setLandScapeData] = useState(null);
    const [showBanner, setShowBanner] = useState(false);

    // =======
    const title = landscapeData != null ? landscapeData.name : '';
    const schemaTitle = landscapeData != null ? landscapeData.name : '';
    const scrollingDisabled = false;
    const authorDisplayName = '';
    const description = landscapeData != null ? landscapeData.description : '';
    const facebookImages = landscapeData && (landscapeData.image ? [landscapeData.image] : []);
    const twitterImages = landscapeData && (landscapeData.image ? [landscapeData.image] : []);
    const schemaImages = landscapeData && (landscapeData.image ? [landscapeData.image] : []);
    const organizationName = '';
    // =======

    const userType = props.user && props.user.userType;
    const profile = userType === 'empresa' ? 'BusinessPage' : 'Personas';

    const richTitle = (
        <span>
            {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
                longWordClass: css.longWord,
            })}
        </span>
    );

    useEffect(() => {
        coreAPI
            .getLandScapeDetailsBySlug(landscapeSlug)
            .then(response => {
                console.log(response);
                setLandScapeData(response.data);
                setLoadProgress(false);
            })
            .catch(error => {
                setLoadProgress(false);
            });
    }, [landscapeSlug]);

    useEffect(() => {
        window.addEventListener('scroll', e => {
            if (window.pageYOffset > 300) {
                setShowBanner(true);
            } else {
                setShowBanner(false);
            }
        });
    });

    if (loadProgress == false && landscapeData === null) {
        return <NotFoundPage />;
    }

    const topbar = <TopbarContainer theme="light"></TopbarContainer>;

    if (loadProgress === true) {
        const loadingTitle = '';
        // const loadingTitle = intl.formatMessage({
        //    id: 'ListingPage.loadingListingTitle',
        //   });
        return (
            <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
                <LayoutSingleColumn className={css.pageRoot}>
                    <LayoutWrapperTopbar>
                        <Header
                            transparent
                            currentPath={props.location.pathname}
                            user={props.user}
                            history={props.history}
                        />
                    </LayoutWrapperTopbar>
                    <LayoutWrapperMain>
                        <p className={css.loadingText}>
                            <FormattedMessage id="ListingPage.loadingListingMessage" />
                        </p>
                    </LayoutWrapperMain>
                    <LayoutWrapperFooter>
                        <Footer history={props.history} />
                    </LayoutWrapperFooter>
                </LayoutSingleColumn>
            </Page>
        );
    }

    return (
        <Page
            title={schemaTitle}
            scrollingDisabled={scrollingDisabled}
            author={authorDisplayName}
            contentType="website"
            description={description}
            facebookImages={facebookImages}
            twitterImages={twitterImages}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'ItemPage',
                description: description,
                name: schemaTitle,
                image: schemaImages,
            }}
        >
            <LayoutSingleColumn className={css.pageRoot}>
                <LayoutWrapperTopbar>
                    <Header
                        transparent
                        currentPath={props.location.pathname}
                        user={props.user}
                        history={props.history}
                    />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain>
                    <div>
                        {showBanner ? (
                            <div className={css.stickyCall}>
                                <div className={css.stickyContainer}>
                                    <p className={css.stickyTitle}> {title} </p>
                                    <NamedLink
                                        className={css.stikyJoinButton}
                                        name={`${profile}`}
                                        to={{ selectedLandscape: landscapeData.id }}
                                    >
                                        <FormattedMessage id="LandscapeListing.supportLandscapePageLink" />
                                    </NamedLink>
                                </div>
                            </div>
                        ) : null}
                        <div className={css.hero}>
                            {landscapeData.id === 6 && (
                                <div className={css.newLabel}>
                                    <FormattedMessage id="LandscapeListing.newLabel" />
                                </div>
                            )}

                            <img
                                className={css.sectionImages}
                                src={landscapeData.image}
                                alt={landscapeData.name}
                            ></img>
                            <div className={css.sectionContentHeading}>
                                <SectionHeading
                                    className={css.heroTitle}
                                    priceTitle={''}
                                    listing={landscapeData}
                                    isOwnListing={false}
                                    formattedPrice={''}
                                    richTitle={richTitle}
                                    category={''}
                                    hostLink={''}
                                    userType={userType}
                                    showContactUser={false}
                                    onContactUser={() => {}}
                                />
                            </div>
                        </div>
                        <div className={css.contentContainer}>
                            <ListingProductContent
                                maps={maps}
                                species={species}
                                currentListing={landscapeData}
                                landscapeId={landscapeData.id}
                                title={title}
                                listing={landscapeData}
                                authorDisplayName={organizationName}
                                onContactUser={() => {}}
                                isEnquiryModalOpen={false}
                                onCloseEnquiryModal={() =>
                                    this.setState({ enquiryModalOpen: false })
                                }
                                sendEnquiryError={() => {}}
                                sendEnquiryInProgress={() => {}}
                                onSubmitEnquiry={() => {}}
                                currentUser={{}}
                                onManageDisableScrolling={() => {}}
                                description={description}
                                mountainsfull={mountainsfull}
                                development={development}
                                enterprise={enterprise}
                                logoGuardians={logoGuardians}
                                logoAliados={logoAliados}
                                SectionHostMaybe={SectionHostMaybe}
                                activities={activities}
                                organizationName={organizationName}
                            />
                        </div>
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer history={props.history} />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
}

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
              userType: storeState.user.currentUser.attributes.profile.userType,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(LandscapePage));
