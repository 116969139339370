import React from 'react';
import styled from 'styled-components';

import { LightGreenBtn, OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

const ThankYouGuardianSection = styled.section`
    background: white;
    padding: 95px 20px;
    width: 100%;
    .title-box {
        img {
            max-width: 67px;
            margin-bottom: 32px;
        }
    }
    .creation-box {
        background-color: white;
        max-width: 415px;
        .input-box-section {
            margin-top: 20px;
            gap: 10px;
        }
    }
    @media (max-width: 500px) {
        .creation-box {
            padding: 100px 20px 120px;
            .input-box-section {
                margin-top: 40px;
            }
        }
    }
`;

const ThankYouGuardian = ({ signedDocumentUrl }) => {
    return (
        <ThankYouGuardianSection className="mx-auto flex justify-center items-center">
            <div className="creation-box mx-auto text-center relative">
                <div className="title-box text-center">
                    <img src="../images/thankyou-ic.svg" alt="creating doc process" />
                    <h2>Thank you</h2>
                </div>
                <p>
                    We have received your Letter of Intent and you will find a copy in your inbox.
                    The Regenera team will get in touch with you within 3 days by e-mail.
                </p>
                <div className="input-box-section flex flex-col">
                    <OrangeBtn>Download document</OrangeBtn>
                    <LightGreenBtn>Back to Regenera</LightGreenBtn>
                </div>
            </div>
        </ThankYouGuardianSection>
    );
};

export default ThankYouGuardian;
