import styled from 'styled-components';
export const DetailFormSection = styled.section`
    max-width: 440px;
    .form-title {
        margin-bottom: 29px;
        .title-info {
            font-size: 16px;
            line-height: 180%;
            font-weight: 400;
        }
    }
    .form-section {
        gap: 16px;
        padding: 0 14px;
        .two-col {
            .input-box-section {
                width: 50%;
            }
        }
        .select-language {
            gap: 8px;
            .radio-box {
                border: 1px solid #ddedd0;
                border-radius: 8px;
                padding-left: 0;
                input {
                    left: 0;
                    right: 0;
                }
                .language-btn {
                    padding: 2px 12px;
                    font-weight: 400;
                    line-height: 180%;
                }
                input:checked + .language-btn {
                    background: #6ea44c;
                    color: white;
                    border-radius: 8px;
                    border-color: #6ea44c;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .googleSignUp {
        width: 100%;
        margin: 20px auto;
        text-align: center;
    }
    .googleSignUp h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #000000;
    }
    .googleSignUp > button {
        width: 100%;
        border-radius: 15px;
    }
    .cta {
        padding: 0 14px;
        max-width: 305px;
        margin-top: 30px;
        margin-bottom: 7px;
        text-align: center;
        button {
            width: 100%;
        }
        .login-cta {
            line-height: 150%;
            margin-top: 8px;
            a {
                font-weight: 600;
                color: ${({ theme }) => theme.colors.title_green};
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.media.tab}) {
        .form-title {
            margin-bottom: 20px;
        }
        .form-section {
            gap: 24px;
            .two-col {
                .input-box-section {
                    width: 100%;
                }
            }
            .select-language {
                gap: 10px;
                .radio-box {
                    flex: 1 0 auto;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`;
